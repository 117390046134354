import {  createSlice } from '@reduxjs/toolkit'
import {ACTIVITIES_CUSTOM_ORDER } from "../../constants";
const initialState = {
  activitiesList: [],
  activitiesloading: true,
}
const userReducer = createSlice({

  name: 'activitiesReducer',
  initialState,
  reducers: {
    getActivities(state) {
      state.activitiesloading = true;
    },
    getActivities_successful(state, action) {
      const reOrderActivities = action.payload.body ?? []
      const customSortedActivities = reOrderActivities.sort((a, b) => {
        return (
          ACTIVITIES_CUSTOM_ORDER.indexOf(a.id) - ACTIVITIES_CUSTOM_ORDER.indexOf(b.id)
        );
    });
      const InjectcustomizeOrder = customSortedActivities.map((existingformat,index) => ({...existingformat, cid: index+1}))
      state.activitiesList = InjectcustomizeOrder || [];
      state.activitiesloading = false;
    },
    getActivities_error(state) {
      state.activitiesloading = false;
    },
  },
})

export const {
  getActivities,
  getActivities_successful,
  getActivities_error,
} = userReducer.actions;

export default userReducer.reducer
