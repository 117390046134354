/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  Fragment,
  useState,
  useEffect,
  forwardRef,
  useRef,
  useImperativeHandle,
} from "react";
import { Box, OutlinedInput, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { submissionCompletionStatus } from "../../../../../utils/helper";
import Loader from "../../../../../components/ui/Loader";
import {
  resetStatus,
  updateSalesVolume,
  getSalesVolume,
  setSalesVolumeActivityData,
} from "../../../../../saga/saga/salesVolumeSaga";
import { openSnackbar } from "../../../../../components/ui/Toastr";
import util from "util";
import Authorization from "../../../../../utils/authorization";
import { apiURL } from "../../../../../services/apiUrlConfig";
import {
  getOrderManageObj,
  setActivityEnable,
} from "../../../../../store/reducers/contracts";
import validator from "../../../../../utils/validator";

const SUCCESS = 200;
const ACTIONTYPE = "EDIT";
const ERROR_MSG = "Please check internet or try again!";
const ERROR_MSG_FIELDS = "Please enter valid number";
const ERROR_MSG_SUBMISSION = "Please fill the required fields!";

const salesVolumeSchema = (src) => {
  return {
    contractids: src.contractIds,
    activitytypeid: src.activityId,
    base: src.Basic,
    silver: src.Silver,
    gold: src.Gold,
    platinum: src.Platinum,
  };
};



const SalesVolumeTextField = (props) => {
  const localizeVlaue = props?.value?parseInt(props?.value).toLocaleString('en-UK').replace(' ',''):0
  const childChangeHandler = (e) =>{
    const formatLocale = e.replaceAll(',','')
    props.onChangeHandler(formatLocale)
  }
  return (
    <Paper
      elevation={0}
      sx={{
        pr: 2,
        display: "flex",
        flexDirection: "column",
        width: props.width,
      }}
    >
      <Typography style={{ fontSize: 12, letterSpacing: 0.3 }}>
        {props.title}
      </Typography>
      <OutlinedInput
        onEndEditing={() => {
          console.log("static compared");
        }}
        variant="outlined"
        type="text"
        sx={{ height: 35 }}
        style={{ fontSize: 12, letterSpacing: 0.3 }}
        value={localizeVlaue || ""}
        placeholder={props.title}
        onChange={(e) =>childChangeHandler(e.target.value)}
      />
    </Paper>
  );
};

const SalesVolume = forwardRef((props, ref) => {
  const temporarySalesVolumeValue = useSelector(
    (state) => state.salesVolumeSaga.SALES
  );
  const [allowNav, setAllowNav] = useState(false);
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const [salesVolum, setSalesVolum] = useState({
    ...temporarySalesVolumeValue,
  });
  const getAccounts = useSelector((state) => state.contracts.accounts);
  const action = useSelector((state) => state.common.ContractActionType);
  const selectedAccountIds =
    getAccounts &&
    getAccounts.map((account) => {
      return account.id;
    });
  const getInterface =
    action.type === ACTIONTYPE ? [action.contratId] : selectedAccountIds;
  const reqBody = salesVolumeSchema({
    contractIds: getInterface,
    activityId: props.id,
    ...salesVolum,
  });
  const salesVolumeSubmitStatus = useSelector(
    (state) => state.salesVolumeSaga.salesVolumeloading
  );
  const salesVolumeSubmitted = useSelector(
    (state) => state.salesVolumeSaga.salesVolumeDetails
  );
  const salesVolumeError = useSelector(
    (state) => state.salesVolumeSaga.salesVolumeError
  );
  const updateApi = apiURL.ppp.updateSalesVolume ?? "";
  const userDetaisl = Authorization.authUser;
  const createContractApi = util.format(updateApi, userDetaisl.id);
  const isAmericanCountry = Authorization.isAmerica()
  // eslint-disable-next-line
  const [currency, setCurrency] = useState(isAmericanCountry?'':'( in € )');
  const SalesVolumeFields = [`Basic`, `Silver`, `Gold`, `Platinum`];
  const SalesVolumeTiltle = "Net invoiced sales volume levels";

  const salesVolumeChange = async (e, itm) => {
    const inputValid = validator.validateNumeric(e);
    if (inputValid) {
      const existingsalesVolume = {
        ...salesVolum,
        [itm]: e,
      };
      // console.log("texerrr::",ValidateEntries(Object.values(existingsalesVolume)))
      dispatch(setActivityEnable({ id: props.id, enable: true }));
      const completionStatus = await submissionCompletionStatus({});
      setSalesVolum({ ...existingsalesVolume });
      dispatch(getOrderManageObj({ status: completionStatus, id: 9 }));
      dispatch(setSalesVolumeActivityData({ ...existingsalesVolume }));
    } else {
      popUpSuccessr(ERROR_MSG_FIELDS, "warning");
    }
  };
  useImperativeHandle(
    ref,
    () => {
      return {
        submitSalesVolumeDetails() {
          const Validtion = Object.values(salesVolum).every(
            (value, index, array) => index === 0 || parseInt(value) >= parseInt(array[index - 1])
          );
          const allowRequest = Object.values(salesVolum).filter(
            (itm) => itm?.length < 1
          );
          console.log("check order::",salesVolum,allowRequest)
          if (
            Object.values(salesVolum).length > 3 &&
            allowRequest?.length === 0 &&
            Validtion
          ) {
            dispatch(
              updateSalesVolume({ url: createContractApi, body: reqBody })
            );
            setAllowNav(true);
          } else {
            popUpSuccessr(
              Validtion
                ? ERROR_MSG_SUBMISSION
                : "Please enter the values in ascending order",
              "warning"
            );
          }
        },
      };
    },
    [salesVolum]
  );

  const popUpSuccessr = (msg, type) => {
    openSnackbar({
      message: msg,
      variant: type,
    });
  };

  useEffect(() => {
    // eslint-disable-next-line
    if (salesVolumeSubmitStatus == false && !salesVolumeSubmitted) {
      const errormsg = salesVolumeSubmitted?.message;
      const noNetworkMessage = ERROR_MSG;
      const msg = salesVolumeSubmitted?.status ? errormsg : noNetworkMessage;
      popUpSuccessr(msg, "error");
      allowNav && dispatch(resetStatus());
      // eslint-disable-next-line
    } else if (salesVolumeSubmitted?.status == SUCCESS) {
      if (
        action.type === ACTIONTYPE &&
        typeof salesVolumeSubmitted.body === "object"
      ) {
        setSalesVolum({ ...temporarySalesVolumeValue });
      } else {
        if (
          action.type !== ACTIONTYPE &&
          typeof salesVolumeSubmitted.body === "object"
        ) {
          setSalesVolum({ ...temporarySalesVolumeValue });
          // props.activitySubmitted(reqBody)
        }
        popUpSuccessr(salesVolumeSubmitted?.message, "success");
        allowNav && props.nextActivity();
        allowNav && dispatch(resetStatus());
        dispatch(setActivityEnable({ id: props.id, enable: false }));
        const completionStatus = submissionCompletionStatus(salesVolum);
        dispatch(getOrderManageObj({ status: completionStatus, id: props.id }));
      }
    }
  }, [salesVolumeSubmitStatus, salesVolumeSubmitted]);

  useEffect(() => {
    if (Object.values(temporarySalesVolumeValue)?.length === 0) {
      if (action.type === ACTIONTYPE) {
        const salesValObj = SalesVolumeFields.reduce(
          (a, v) => ({ ...a, [v]: "loading..." }),
          {}
        );
        setSalesVolum({ ...salesValObj });
        const getApi = apiURL.ppp.getSalesVolume ?? "";
        const createGetApi = util.format(
          getApi,
          userDetaisl.id,
          action.contratId
        );
        dispatch(getSalesVolume(createGetApi));
      } else {
        const salesValObj = SalesVolumeFields.reduce(
          (a, v) => ({ ...a, [v]: "" }),
          {}
        );
        setSalesVolum({ ...salesValObj });
      }
    }
  }, []);
  const decimalOptions = {
    minimumFractionDigits: 3,
    maximumFractionDigits: 5
  }
  return (
    <Fragment>
      {salesVolumeSubmitStatus && !salesVolumeError && <Loader />}
      <Box ref={inputRef} sx={{ display: "flex", flexDirection: "column" }}>
        <Typography>{SalesVolumeTiltle}</Typography>
        <Typography style={{fontSize:'10px', marginTop:'10px'}}>(Please enter the maximum value of the range.</Typography>
        <Typography style={{fontSize:'10px'}}>For example: if your range is between 0-6,000,000 then enter 6,000,000 as the value)</Typography>
        <Paper elevation={0} sx={{ pt: 2, display: "flex" }}>
          {SalesVolumeFields.map((itm, index) => {
            return (
              <SalesVolumeTextField
                title={itm+`${currency}`}
                width="90"
                value={salesVolum[itm]}
                onChangeHandler={(e) => salesVolumeChange(e, itm)}
              />
            );
          })}
        </Paper>
      </Box>
    </Fragment>
  );
});

export default SalesVolume;
