// ==============================|| OVERRIDES - TOOLTIP ||============================== //
const blackColor= "rgba(0, 0, 0, 0.85)"
export default function Tooltip(theme) {
  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor:blackColor,
        },
        arrow: {
          color:blackColor,
        },
      },
    },
  };
}
