import { useState,useEffect } from "react";
import ReactSelect from "../../../components/third-party/ReactSelect";
import Box from "@mui/material/Box";
import {useSelector} from "react-redux";

const  dropdownTEFOptions=[
    { value: "ALL", label: "ALL" },
    { value: "Distributor", label: "Distributor" },
    { value: "MD", label: "MD" },
    { value: "Sales", label: "Sales" }
  ]

const TrialExecFilter = ({getValue}) => {
  const [selectedValue, setSelectedValue] = useState("ALL");
  const { selectedTrialExec } = useSelector((state) => state.common);

  useEffect(() => {
    if (selectedTrialExec) setSelectedValue(selectedTrialExec) ;
  }, [selectedTrialExec]);

  const handleSelect = (option) => {
    getValue(option?.value,"selectedTrialExec")
    setSelectedValue(option?.value);
  };

  return (
    <Box>
      <ReactSelect
        name={"trialExecution"}
        options={dropdownTEFOptions}
        handleChange={(selectedOptions, e) => {
          handleSelect(selectedOptions);
        }}
        value={selectedValue}
        id="trial-test"
        label="Trial Execution"
        width={250}
        defaultValue="ALL"
      />
    </Box>
  );
};
export default TrialExecFilter;
