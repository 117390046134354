/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useState, useEffect, forwardRef, useRef, useImperativeHandle, Fragment } from "react";
import Grid from '@mui/material/Grid';
import {styled } from '@mui/material/styles';
import {Paper,Box} from '@mui/material'; 
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import FieldView from './FieldView'
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../../../components/ui/Loader";
import { openSnackbar } from "../../../../../../components/ui/Toastr";
import util from 'util'
import Authorization from "../../../../../../utils/authorization"
import { apiURL } from "../../../../../../services/apiUrlConfig";
import { 
    resetStatus, 
    updatemarketIntelligence, 
    getmarketIntelligence,
    } from "../../../../../../saga/saga/MarketIntelligenceSaga"
import CustomTable from "../../../../../../components/ui/MainTable";
import { getMarketIntelligenceSelected, getOrderManageObj,setActivityEnable } from "../../../../../../store/reducers/contracts";
//Common Referencess
    const SUCCESS = 200
    const ERROR_MSG = 'Please check internet or try again!'
    const ACTIONTYPE = 'EDIT'
 
    const CustomMenu = styled(MenuItem)({
        height:30,
        fontSize:11,
        letterSpacing:0.5
      });
      const StyledSelect = styled(Select)(({ theme }) => ({
        '& .MuiInputBase-input':{
         display:'block',
         textOverflow: "ellipsis",
       }
       }));
//object  referencef for table loading
function createData(sfid, marketintelligencename, materialgroup, pmc, subregion, country, currency, marketvalue,isselected) {
    return {
        sfid,
        marketintelligencename,
        materialgroup,
        pmc,
        subregion,
        country,
        currency,
        marketvalue,
        isselected
    };
}

//table headers
const headCells = [
    {
        id: "marketintelligencename",
        numeric: false,
        disablePadding: true,
        label: "Market intelligence name",
        minWidth: 180,
    },
    {
        id: "materialgroup",
        numeric: false,
        disablePadding: true,
        label: "Material group",
        minWidth: 90,
    },
    {
        id: "pmc",
        numeric: true,
        disablePadding: true,
        label: "PMC",
        minWidth: 120,
    },
    {
        id: "subregion",
        numeric: true,
        disablePadding: true,
        label: "Sub region",
        minWidth: 60,
    },
    {
        id: "country",
        numeric: true,
        disablePadding: true,
        label: "Country",
        minWidth: 60,
    },
    {
        id: "currency",
        numeric: true,
        disablePadding: true,
        label: "Currency",
        minWidth: 50,
    },
    {
        id: "marketvalue",
        numeric: true,
        disablePadding: false,
        label: "Market value",
        minWidth: 60,
    },
];

// const PMCTITLE = 'List Of PMC';
// const useStyles = {
//     ListOfVarietes: {
//         minHeight: 320,
//         marginRight: 17,
//     },
//     Headerofvarieties: {
//         width: "100%",
//         height: 40,
//         backgroundColor: '#EBF0F2',
//         color: 'black',
//         fontSize: 12,
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'start',
//         paddingLeft: 10
//     }
// }

const Item = styled(Paper)(({ theme }) => ({
    textAlign: 'center',
}));


//post request schema
const forcastPostSchema = (accountId,activityId,date,sfids) => {
   return({
        "contractids": accountId,
        "activitytypeid": activityId,
        "duedate": date,
        "sfids": sfids
    })
}

const MarketIntelligence = forwardRef((props,ref) => {

    const [selectedDate, setSelectedDate] = useState('');
    const [list, setList] = useState([]);  
    const [shallowList, setShallowList] = useState([]);  
    const [matList, setMatList] = useState([]);  
    const [pmcList, setpmcList] = useState([]);  
    const [countryList, setcountryList] = useState([]);  

    const [subregionList, setsubregionList] = useState([]);  

    // eslint-disable-next-line
    const [selectedlist, setSelectedlist] = useState([]);
    const [allowNav, setAllowNav] = useState(false);
    const [filMaterialgroup, setFilMaterialgroup] = useState('all');
    const [filPmc, setFilPmc] = useState('all');
    const [filSubregion, setFilSubregion] = useState('all');
    const [filCountry, setfilCountry] = useState('all');

//MarketIntellgnce
    const MiStatus = useSelector((state) => state.MarketIntelligenceSaga.marketIntelligenceloading);
    const MiDetails = useSelector((state) => state.MarketIntelligenceSaga.marketIntelligenceDetails);
    const MiError = useSelector((state) => state.MarketIntelligenceSaga.marketIntelligenceError);
    const MarketIntellgnce = useSelector((state) => state.MarketIntelligenceSaga.MarketIntellgnce);

    const getAccounts = useSelector((state) => state.contracts.accounts);
    const selectedAccountIds = getAccounts && getAccounts.map((account) => { return account.id; });
    
    const marketIntSelection = useSelector((state) => state.contracts.marketInt);
    const action = useSelector((state) => state.common.ContractActionType)
    const dispatch  = useDispatch()
    const inputRef = useRef(null);


    const getApi = apiURL.ppp.getMarketIntekkigence ?? ''
    const postApi = apiURL.ppp.updateMarketIntelligence ?? ''

    const userDetaisl = Authorization.authUser
    const createGetApi = util.format(getApi, userDetaisl.id)
    const submitApi = util.format(postApi, userDetaisl.id)


  useEffect(()=>{
        const sprdList = [...marketIntSelection,...list]
          console.log("listsetlist::",sprdList,marketIntSelection,list)
          var getUniqMarketGroups =  sprdList&&sprdList.reduce((unique, o) => {
            if(!unique.some(obj => obj.sfid === o.sfid)) {
              unique.push(o);
            }
            return unique;
        },[]);  
        setList([...getUniqMarketGroups] || [])
        setShallowList(marketIntSelection)
        initialMatFil(getUniqMarketGroups)
  },[marketIntSelection])

  const initialMatFil = async(source) => {
     const reqMatGroup = await refillObjUniq(source)
     setcountryList(reqMatGroup)
  }

  const refillObjUniq = (source) => {
    var getUniqMarketGroups =  source&&source.reduce((unique, o) => {
        if(!unique.some(obj => obj.country === o.country)) {
          unique.push(o);
        }
        return unique;
    },[]);
    return getUniqMarketGroups
  }
 // getting called from parent post process iterface    
  useImperativeHandle(ref, () => {
    return {
        submitMi(){
            // eslint-disable-next-line
            const idsel = list?.length > 0 && list.filter((itm) => { 
                if(itm.isselected){
                 return itm
                 }
             })
    
            const sfids = idsel?.length > 0 ? idsel.map((itm) => { return itm.sfid }): []
            if (list?.length>=0&&sfids?.length >= 0&&selectedDate) {
                setAllowNav(true)
                const getInterface = action.type === ACTIONTYPE ? [action.contratId] : selectedAccountIds
                const reqBody =  forcastPostSchema(getInterface, props.id, selectedDate, sfids)
                dispatch(updatemarketIntelligence({ url: submitApi, body: reqBody }))
            }else{
                if(!selectedDate){
                    popUpSuccessr('please enter the date field','error')
                }else{
                    popUpSuccessr('please select at least of account from the list','error')
                }
            }
        }
    }

  }, [selectedDate,selectedlist,list]);


  //innitial get iterface
    useEffect(() => {
            const getInterface = action.type === ACTIONTYPE ? action.contratId : selectedAccountIds
            dispatch(getmarketIntelligence(createGetApi+`[${getInterface}]`))
      }, [])



    useEffect(()=>{
    const getRespFormat = getttingResponse(MarketIntellgnce)
    Promise.all([getRespFormat]).then((values) => {
        setList(values[0])
        setShallowList(values[0])
        initialMatFil(values[0])
       })
    MiDetails?.body&&setSelectedDate(MiDetails?.body?.duedate)

    },[MiDetails])


const getttingResponse = async(details) => {
  const farmatResp = await createListReady(details)
  return farmatResp
}

const createListReady = (lis) => {
    let prepareList = []
     if(lis&&lis?.length > 0)
     {
         prepareList =  lis.map((itm)=>{
                return createData(itm.sfid, itm.marketintelligence, itm.materialgroup, itm.pmc, itm.assubregion, itm.country_name__c, itm.currencyisocode, itm.marketvalue,itm.isselected) 
         })
     }
     return prepareList
}

    const popUpSuccessr = (msg, type) => {
        openSnackbar({
          message: msg,
          variant: type,
        });
      }

      useEffect(() => {
        if (MiStatus === false && !MiDetails) {
          const errormsg = MiDetails?.message
          const noNetworkMessage = ERROR_MSG
          const msg = (MiDetails?.status) ? errormsg : noNetworkMessage
          popUpSuccessr(msg, 'error')
          dispatch(resetStatus())
        } else if (MiDetails?.status === SUCCESS) {
            dispatch(resetStatus())
            popUpSuccessr(MiDetails?.message, 'success')
            allowNav && props.nextActivity()
            allowNav&&dispatch(setActivityEnable({ id: 4, enable: false }))
            allowNav&&dispatch(getOrderManageObj({ status: 'DONE', id: 4 }))
          
        };
      }, [MiStatus, MiDetails]);
    
    const getFilterData = async(filterDatas) => {
        // dispatch(getOrderManageObj({ status: 'INPROGRESS', id: 4 }))
      //   const sellist = filterDatas.selectedRow
      //   const sprdList = [...sellist,...list]
      //   console.log("listsetlist::",sprdList,sellist,list)
      //   var getUniqMarketGroups =  sprdList&&sprdList.reduce((unique, o) => {
      //     if(!unique.some(obj => obj.sfid === o.sfid)) {
      //       unique.push(o);
      //     }
      //     return unique;
      // },[]);
      // console.log("listsetlist2::",getUniqMarketGroups)

      //   setList([...getUniqMarketGroups] || [])
        // console.log("spread3",[...sprdList] || [])
        // setShallowList([...sprdList] || [])
        // initialMatFil([...sprdList] || [])
        dispatch(getMarketIntelligenceSelected(filterDatas.selectedRow))
       dispatch(setActivityEnable({ id: 4, enable: true }))
    };
    const dateChange = (dateSel) =>{
        setSelectedDate(dateSel)
        // dispatch(getOrderManageObj({ status: 'INPROGRESS', id: 4 }))
    }

    const FilterField =(props)=>{

        const handleChange = (event) => {
          props.selectedValue(event.target.value);
        };
        return(
          <Box elevation={0} sx={{display:'flex'}} style={{flexDirection:'column'}}>
            <Typography style={{paddingLeft:0,display:'flex',alignItem:'center',justifyContent:'left',fontSize:'11px',letterSpacing:0.5}} component='label'>
              {props.title}
              </Typography>
            <FormControl sx={{ mr: 2, minWidth: 170 }} size="small">
          <StyledSelect
          name={props.name}
            labelId={props.name}
            id={props.name}
            value={props.value}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'Without label' }}
            style={{width:170,fontSize:11,display:'flex',letterSpacing:0.5}}
            sx={{display:'flex'}}
          >
            <CustomMenu value='all'>{props?.allLabel}</CustomMenu>
           {props.src?.length > 0&&(props.src).map((itm)=>{
              return <CustomMenu value={itm[props.name]}>{itm[props.name]}</CustomMenu>
           }) }
          </StyledSelect>
        </FormControl>
          </Box>
          )
      }
const filCountrySelect = async(value)=>{
    if(value === 'all'){
        setShallowList([...list])
        setMatList([])
        setpmcList([])
        setsubregionList([])

        setFilMaterialgroup(value)
        setFilSubregion(value)
        setFilPmc(value)
        setfilCountry(value)
    }else{
     const filpmcbymgcntry = list&&list.filter(val=>val['country'] === value)
     setShallowList(filpmcbymgcntry)
     var getUniqMarketGroups = filpmcbymgcntry&&filpmcbymgcntry.reduce((unique, o) => {
        if(!unique.some(obj => obj['subregion'] === o['subregion'])) {
          unique.push(o);
        }
        return unique;
    },[]);
    setsubregionList(getUniqMarketGroups)
    setfilCountry(value)
    setFilMaterialgroup('all')
    setFilSubregion('all')
    setMatList([])
    setpmcList([])
    setFilPmc('all')

    }
}
      
 const filMaterialgroupSelect = async(value)=>{
    if(value === 'all'){
        const filpmcbymgcntry = list&&list.filter(val=>val['country'] === filCountry)
        setShallowList(filpmcbymgcntry)
        setpmcList([])
    
        setFilMaterialgroup(value)
        setFilPmc(value)
    }else{
    const filpmcbymgcntry = list&&list.filter(val=>val['country'] === filCountry)
    const filpmcbymg = filpmcbymgcntry&&filpmcbymgcntry.filter(val=>val['subregion'] === filSubregion)
    const filpmcbymgrp = filpmcbymg&&filpmcbymg.filter(val=>val['materialgroup'] === value)
    setShallowList(filpmcbymgrp)
    var getUniqMarketGroups = filpmcbymgrp&&filpmcbymgrp.reduce((unique, o) => {
        if(!unique.some(obj => obj['pmc'] === o['pmc'])) {
          unique.push(o);
        }
        return unique;
    },[]);
    setpmcList(getUniqMarketGroups)
    setFilMaterialgroup(value)
    setFilPmc('all')
  }
 }
 const filPmcSelect =(value)=>{
     if(value === 'all'){
        const filpmcbymgcntry = list&&list.filter(val=>val['country'] === filCountry)
        const filpmcbymg = filpmcbymgcntry&&filpmcbymgcntry.filter(val=>val['subregion'] === filSubregion)
        const filpmcbymgrp = filpmcbymg&&filpmcbymg.filter(val=>val['materialgroup'] === filMaterialgroup)
        setShallowList(filpmcbymgrp)
        setFilPmc(value)
     }else{
    const filpmcbymgcntry = list&&list.filter(val=>val['country'] === filCountry)
    const filpmcbymg = filpmcbymgcntry&&filpmcbymgcntry.filter(val=>val['subregion'] === filSubregion)
    const filpmcbymgrp = filpmcbymg&&filpmcbymg.filter(val=>val['materialgroup'] === filMaterialgroup)
    const filpmcbymgrpmc = filpmcbymgrp&&filpmcbymgrp.filter(val=>val['pmc'] === value)
    setShallowList(filpmcbymgrpmc)
    var getUniqMarketGroups = filpmcbymgrp&&filpmcbymgrp.reduce((unique, o) => {
        if(!unique.some(obj => obj['pmc'] === o['pmc'])) {
          unique.push(o);
        }
        return unique;
    },[]);
    setpmcList(getUniqMarketGroups)
    setFilPmc(value)
}
 }
 const filSubregionSelect =(value)=>{
     if(value === 'all'){
       const filpmcbymgcntry = list&&list.filter(val=>val['country'] === filCountry)
       setShallowList(filpmcbymgcntry)
       setMatList([])
       setpmcList([])

       setFilMaterialgroup(value)
       setFilSubregion(value)
       setFilPmc(value)
     }else{
        const filpmcbymgcntry = list&&list.filter(val=>val['country'] === filCountry)
        const filpmcbymg = filpmcbymgcntry&&filpmcbymgcntry.filter(val=>val['subregion'] === value)
        var getUniqMarketGroups = filpmcbymg&&filpmcbymg.reduce((unique, o) => {
            if(!unique.some(obj => obj['materialgroup'] === o['materialgroup'])) {
              unique.push(o);
            }
            return unique;
        },[]);
        setShallowList(filpmcbymg)
        setMatList(getUniqMarketGroups)
        setFilSubregion(value)
        setFilPmc('all')
        setFilMaterialgroup('all')
        setpmcList([])


     }
    //  if(value === 'all'){
    //     const filpmcbymg = list&&list.filter(val=>val['materialgroup'] === filMaterialgroup)
    //     const filsubregbypmc = filpmcbymg&&filpmcbymg.filter(val=>val['pmc'] === filPmc)
    //     setShallowList(filsubregbypmc)
    //     setFilSubregion(value)
    //  }else{
    // const filpmcbymg = list&&list.filter(val=>val['materialgroup'] === filMaterialgroup)
    // const filsubregbypmc = filpmcbymg&&filpmcbymg.filter(val=>val['pmc'] === filPmc)
    // const filsubreg = filsubregbypmc&&filsubregbypmc.filter(val=>val['assubregion'] === value)
    // setShallowList(filsubreg)
    // setFilSubregion(value)
    //  }
 }

    return (
        <Fragment>
        {(MiStatus && !MiError) && <Loader />}
        <Grid ref={inputRef} container spacing={2}>
            <Grid item xs={12}>
                <Item elevation={0}  style={{height:100,marginTop:'-10px' }}>
                <FieldView selectedDate={selectedDate} setDueDate ={(date)=>dateChange(date)} />
                </Item >
                <Box sx={{
            height: {
              xl: window.innerHeight - 310,
              lg: "auto",
              overflow: "auto",              
            },
          }} >
                <Paper style={{display:'flex',width:'100%',height:70,borderRadius:0}}>
                <FilterField name={'country'} value={filCountry} selectedValue={(value)=>filCountrySelect(value)}  src={countryList} title='Country' allLabel="All countries"/>
                <FilterField name={'subregion'} value={filSubregion} selectedValue={(value)=>filSubregionSelect(value)}  src={subregionList} title='Sub region' allLabel="All sub regions"/>
                <FilterField name={'materialgroup'} value={filMaterialgroup} selectedValue={(value)=>filMaterialgroupSelect(value)}  src={matList} title='Material group' allLabel="All material groups"/>
                <FilterField name={'pmc'} value={filPmc} selectedValue={(value)=>filPmcSelect(value)}  src={pmcList} title='PMC' allLabel="All pmc's"/>
                </Paper>
                <CustomTable
                 from='MI'
                 key = "MI"
                 statusLoad={false}
                 rows={shallowList}
                 headCells={headCells}
                 getFilterData={(filterData)=>getFilterData(filterData)}
                 isPagination={false}
                 selectedChecked={selectedlist}
                 ></CustomTable>
                 </Box>
            </Grid>
            {/* <Grid item xs={3}>
                <Item elevation={0}>
                    <ListOfVarieties />
                </Item>
            </Grid> */}
        </Grid>
        </Fragment>
    )
})
export default memo(MarketIntelligence)