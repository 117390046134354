import { createSlice } from '@reduxjs/toolkit'


const initialState = {
    marketIntelligenceDetails: {},
    marketIntelligenceloading: false,
    marketIntelligenceError: false,
    MarketIntellgnce: [],
    updateAdvancementloading: false,

}
const userReducer = createSlice({

    name: 'advancementtrailsReducer',
    initialState,
    reducers: {
        setadvTrailsActivityData(state, action) {
            return {...state, MarketIntellgnce : action.payload}
        },
        getAdvTrails(state) {
            return { ...state, marketIntelligenceloading: true,MarketIntellgnce:[] };
        },
        updateAdvTrails(state) {
            return { ...state, marketIntelligenceloading: true };
        },
        getadvTrails_successful(state, action) {
            return {
                ...state, marketIntelligenceDetails: action.payload,
                marketIntelligenceloading: false
            }
        },
        getadvTrails_error(state, action) {
            return {
                ...state, marketIntelligenceDetails: action.payload.body,
                marketIntelligenceError: true
            }
        },
        resetStatus(state) {
            return {
                ...state, marketIntelligenceloading: false,
                marketIntelligenceError: false,
                marketIntelligenceDetails: {},
            };
        },
    }
})
export const {
    getAdvTrails,
    getadvTrails_successful,
    getadvTrails_error,
    resetStatus,
    // updatemarketIntelligence,
    updateAdvTrails,
    setadvTrailsActivityData
} = userReducer.actions;

export default userReducer.reducer