import { Fragment, forwardRef } from "react";
import MainCard from "../../components/MainCard";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TableVirtuoso } from "react-virtuoso";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";

const RenderEmptyPlaceholder = (props) => {
  return (
    <TableBody>
      <TableRow>
        <TableCell colSpan={props?.headers?.length} style={{ textAlign: "center" }}>
          No Records Found!
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

const VirtuosoTableComponents = (headers) => {
  return {
    Scroller: forwardRef((props, ref) => <TableContainer {...props} ref={ref} />),
    Table: (props) => <Table {...props} sx={{ borderCollapse: "separate", tableLayout: "fixed" }} />,
    TableHead,
    TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
    TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
    EmptyPlaceholder: (props) => <RenderEmptyPlaceholder {...props} headers={headers} />,
  };
};

function fixedHeaderContent(columns) {
  return (
    <TableRow>
      {columns.map((column) => (
        <TableCell
          key={column.dataKey}
          variant="head"
          align={column.numeric || false ? "right" : "left"}
          style={{ width: column.width }}
          sx={{
            backgroundColor: "#388e3c",
            color: "#fff",
            paddingBottom: 1,
            paddingTop: 1,
          }}
        >
          {column.label}
        </TableCell>
      ))}
    </TableRow>
  );
}

function rowContent(_index, row, columns) {
  return (
    <Fragment>
      {columns.map((column) => (
        <TableCell key={column.dataKey} align={column.numeric || false ? "right" : "left"}>
          {column.dataKey !== "serial" ? row[column.dataKey] : _index + 1}
        </TableCell>
      ))}
    </Fragment>
  );
}

const ReactVirtualizedTable = ({ rows, headers }) => {
  return (
    <TableVirtuoso
      data={rows}
      components={VirtuosoTableComponents(headers)}
      fixedHeaderContent={() => fixedHeaderContent(headers)}
      itemContent={(_index, row) => rowContent(_index, row, headers)}
      style={{ height: 300, width: "100%" }}
    />
  );
};
const LeaderBoardTable = ({ title, rows, headers, isDownload, download }) => {
  return (
    <Fragment>
      <MainCard
        title={title}
        elevation={0}
        border={false}
        content={true}
        contentSX={{ p: 0 }}
        headerSX={{ p: 1.5, color: "#707070" }}
        secondary={
          isDownload && (
            <Avatar sx={{ width: 30, height: 30, backgroundColor: "rgb(224 224 224)" }} variant="rounded">
              <IconButton aria-label="download" onClick={download}>
                <FileDownloadOutlinedIcon sx={{ fill: "#388e3c" }} />
              </IconButton>
            </Avatar>
          )
        }
      >
        <ReactVirtualizedTable rows={rows} headers={headers} />
      </MainCard>
    </Fragment>
  );
};
export default LeaderBoardTable;
