/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState, forwardRef, useRef, useImperativeHandle } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import authorization from '../../../../../../utils/authorization';
import { openSnackbar } from '../../../../../../components/ui/Toastr';
import { getRequest } from '../../../../../../services/apiUtils';
import util from 'util'
import Loader from '../../../../../../components/ui/Loader';
import { getOrderManageObj, setActivityEnable } from "../../../../../../store/reducers/contracts";
import { useDispatch, useSelector } from "react-redux";
import { styled } from '@mui/material/styles';
import { apiURL } from '../../../../../../services/apiUrlConfig';
import { getDemandGeneration, resetStatus } from '../../../../../../saga/saga/demandGenerationSaga'
import { Skeleton } from '@mui/material';



const SUCCESS = 200;
const ACTIONTYPE = "EDIT";
const ERROR_MSG = "Please check internet or try again!";



const demandGenerationSchema = (ids, activitytypeId) => {
    return ({
        "contractids": ids,
        "activitytypeid": activitytypeId
    })
}


function createData(trialplanname, crop, linesubject, year, mdcoordinator, trialexecution, set, trailloc, totalentries, totalplots, accountname) {
    return {
        trialplanname,
        crop,
        linesubject,
        year,
        mdcoordinator,
        trialexecution,
        set,
        trailloc,
        totalentries,
        totalplots,
        accountname
    };
}

const headCells = [
    {
        id: "trialplanname",
        numeric: false,
        disablePadding: true,
        label: "Trial plan name",
        minWidth: 90,
    },
    {
        id: "crop",
        numeric: false,
        disablePadding: true,
        label: "Crop",
        minWidth: 70,
    },
    {
        id: "linesubject",
        numeric: true,
        disablePadding: true,
        label: "Trial plan line subject",
        minWidth: 80,
    },
    {
        id: "year",
        numeric: true,
        disablePadding: true,
        label: "Year",
        minWidth: 70,
    },
    {
        id: "mdcoordinator",
        numeric: true,
        disablePadding: true,
        label: "MD coordinator",
        minWidth: 40,
    },
    // {
    //     id: "trialexecution",
    //     numeric: true,
    //     disablePadding: true,
    //     label: "Trial Execution",
    //     minWidth: 50,
    // },
    {
        id: "set",
        numeric: true,
        disablePadding: true,
        label: "Set",
        minWidth: 40,
    },
    {
        id: "trailloc",
        numeric: true,
        disablePadding: false,
        label: "Trial loss",
        minWidth: 30,
    },
    {
        id: "totalentries",
        numeric: true,
        disablePadding: false,
        label: "Total entries",
        minWidth: 30,
    },
    {
        id: "totalplots",
        numeric: true,
        disablePadding: false,
        label: "Total plots",
        minWidth: 90,
    },
    {
        id: "accountname",
        numeric: true,
        disablePadding: false,
        label: "Account name",
        minWidth: 110,
    },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#00617F',
        color: theme.palette.common.white,
        paddingTop: 5,
        paddingBottom: 5,
        fontSize: 11,
        fontWeight: 100
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 11,
        paddingTop: 10,
        paddingBottom: 9,
        fontWeight: 100
    },
}));

const DemandGeneration = forwardRef((props, ref) => {
    const [rows, setRows] = React.useState([])
    const [allowNav, setAllowNav] = useState(false);
    const [statusLoad,setStatusLoad] = useState(false);
    const inputRef = useRef(null)
    const dispatch = useDispatch()
    const action = useSelector((state) => state.common.ContractActionType)
    const getAccounts = useSelector((state) => state.contracts.accounts);
    const selectedAccountIds = getAccounts && getAccounts.map((account) => { return account.id; });
    const getInterface = action.type === ACTIONTYPE ? [action.contratId] : [...new Set(selectedAccountIds)];
    const dgsSelect = [...new Set(selectedAccountIds)]
    const authUser = authorization.getAuthUser();
    const getApi = apiURL.ppp.demandgeneration ?? ''
    const dgGetApi = util.format(getApi, authUser.id)
    const dgApiUrl = dgGetApi + `[${getInterface}]`+ '&activitytypeid=5'
    const updateApi = apiURL.ppp.updateOrderPayment ?? ''
    const createContractApi = util.format(updateApi, authUser.id)

    const paymentSubmitStatus = useSelector(
        (state) => state.demandGenerationSaga.demandGenerationloading
    );
    const paymentSubmitted = useSelector(
        (state) => state.demandGenerationSaga.demandGeneration
    );
    const orpaymentSubmitError = useSelector(
        (state) => state.demandGenerationSaga.demandGenerationError
    ); //

    const reqBody = demandGenerationSchema(dgsSelect, props.id)

    useImperativeHandle(ref, () => {
        return {
            moveToNextActivity() {
                dispatch(getDemandGeneration({ url: createContractApi, body: reqBody }))
                setAllowNav(true)
            }
        }
    }, [rows]);

    const getDGData = () => {
        setStatusLoad(true)
        getRequest({
            // url: `ppp/dg_trials/${authUser.id}?contractids=[${getInterface}] `,
            url: dgApiUrl,
        }).then((response) => {
            const result = response?.body;
            if (result?.length > 0) {
                const rowData = result.map((item, key) => {
                    return createData(item.trialplanname, item.crop, item.linesubject, item.year, item.mdcoordinator, item.trialexecution, item.set, item.trailloc, item.totalentries, item.totalplots, item.accountname)
                })
                setRows(rowData);
                setStatusLoad(false)
            } else {
                setStatusLoad(false)
            }
        })
            .catch((error) => {
                openSnackbar({ message: "No Data Found!", variant: "error" });
                setStatusLoad(false)
            });
    }

    React.useEffect(() => {
        getDGData();
    }, [])

    const popUpSuccessr = (msg, type) => {
        openSnackbar({
            message: msg,
            variant: type,
        });
    };

    const LoadWhile = () => {
        return statusLoad ? (
            [...Array(4)].map((item, itemIndex) => {
                return (
                    <TableRow key={itemIndex}>
                        <TableCell
                            colSpan={headCells?.length + 1}
                            sx={{ textAlign: "center" }}
                            style={{
                                borderBottom: "0px solid transparent",
                                paddingBottom: 0.5,
                                paddingRight: 2,
                                paddingLeft: 0,
                            }}
                        >
                            <Skeleton
                                animation="wave"
                                variant="rectangular"
                                sx={{ height: 60, width: "100%", backgroundColor: "#F2F2F2" }}
                            />
                        </TableCell>
                    </TableRow>
                );
            })
        ) : (
            <TableRow>
                <TableCell colSpan={headCells?.length + 1} sx={{ textAlign: "center" }}>
                    {" "}
                    No records found!
                </TableCell>
            </TableRow>
        );
    };


    useEffect(() => {
        if (paymentSubmitStatus === false && !paymentSubmitted) {
            const errormsg = paymentSubmitted?.message;
            const noNetworkMessage = ERROR_MSG;
            const msg = paymentSubmitted?.status ? errormsg : noNetworkMessage;
            popUpSuccessr(msg, "error");
            allowNav && dispatch(resetStatus());
        } else if (paymentSubmitted?.status === SUCCESS) {

            popUpSuccessr(paymentSubmitted?.message, "success");
            allowNav && props.nextActivity();
            allowNav && dispatch(resetStatus());
            dispatch(setActivityEnable({ id: 5, enable: false }));
            allowNav && dispatch(getOrderManageObj({ status: 'DONE', id: 5 }));
        }
    }, [paymentSubmitStatus, paymentSubmitted]);


    return (
        <Fragment>
            {paymentSubmitStatus && !orpaymentSubmitError && <Loader />}
            <TableContainer style={{ overflow: 'auto', height: 370 }} ref={inputRef} component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead sx={{ backgroundColor: '#1a648d' }}>
                        <TableRow>
                            {
                                headCells.map((item, index) => (
                                    <StyledTableCell key={index} width={item.minWidth} sx={{ color: 'white', fontWeight: 'normal' }}>{item.label}</StyledTableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rows?.length > 0 && headCells?.length > 0) ? rows.map((row, index) => (
                            <TableRow
                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                {headCells?.length > 0 && headCells.map((itm) => {
                                    return <StyledTableCell style={{ minWidth: itm.minWidth }} align="left">{(row[itm?.id] && row[itm?.id]) ? (row[itm?.id] && row[itm?.id]) : '-'}</StyledTableCell>
                                })}
                            </TableRow>
                        )) : <LoadWhile />}

                    </TableBody>
                </Table>
            </TableContainer>
        </Fragment>
    )
})

export default DemandGeneration