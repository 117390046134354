export const CMM_ROLE = "CMM";
export const ADMIN_ROLE = "ADMIN";
export const DEFAULT_NAVI_MESSAGE = "There are unsaved changes. Are you sure want to leave this page?";
export const LOGOUT_NAVI_MESSAGE = "Are you sure want to logout?";
export const CONTRACT_DOCUMENT_POPUP_MESSAGE = "There are unsaved changes. Are you sure want to close?";
export const CONTRACT_YEAR = Number(process.env.REACT_APP_CONTRACT_YEAR);
export const PAYMENT_MANAGEMENT_COUNTRY_DAYS = {
  TR: [9], // Turkey
  MA: [30], // Morocco
  DZ: [30], // Algeria
  TN: [30], // Tunisia
  EG: [30], // Egypt
  IQ: [30], // Iraq
  JO: [30], // Jordan
  LB: [30], // Lebanon
  SY: [30], // Syria
  SA: [30], // Saudi
  OTHERS: [5]
};
export const ACTIVITIES_CUSTOM_ORDER = [1,2,3,4,5,12,6,7,8,9,10,11]
export const ACTIVITIES_HELPER_TEXT = [
  { url: "https://mvs--test.sandbox.my.salesforce.com/a0l?fcf=00BE0000004Io4V", env: "UAT", type: "SALES_TARGET" ,activities: 3},
  { url: "https://mvs--test.sandbox.my.salesforce.com/a19?fcf=00BE0000004h1R5", env: "UAT", type: "MARKET_INTELLIGENCE",activities:4 },
  { url: "https://mvs--test.sandbox.my.salesforce.com/a6C?fcf=00BUu0000008JqH", env: "UAT", type: "TRIAL_PLANS",activities:5 },
  { url: "https://mvs--test.sandbox.my.salesforce.com/a6C?fcf=00BUu0000008JqH", env: "UAT", type: "TRIAL_PLANS",activities:12 },
  { url: "https://mvs.my.salesforce.com/a0l?fcf=00BE0000004Io4V", env: "PROD", type: "SALES_TARGET" ,activities: 3},
  { url: "https://mvs.my.salesforce.com/a19?fcf=00BE0000004h1R5", env: "PROD", type: "MARKET_INTELLIGENCE",activities:4 },
  { url: "https://mvs.my.salesforce.com/a19?fcf=00BE0000004h1R5", env: "PROD", type: "TRIAL_PLANS",activities:5 },
  { url: "https://mvs.my.salesforce.com/a19?fcf=00BE0000004h1R5", env: "PROD", type: "TRIAL_PLANS",activities:12 },
];
