/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import ReactSelect from "../../../components/third-party/ReactSelect";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";




const CountrySelect = ({getValue,options}) => {
  const [selectedValue, setSelectedValue] = useState("ALL");
  const { countryCode } = useSelector((state) => state.common);

  const handleSelect = (option) => {
    getValue(option?.value,"countryCode")
    setSelectedValue(option?.value);
  };


  useEffect(() => {
    if (countryCode) setSelectedValue(countryCode) ;
  }, [countryCode]);



  return (
    <Box>
      <ReactSelect
        name={"country"}
        options={options}
        handleChange={(selectedOptions, e) => {
          handleSelect(selectedOptions);
        }}
        value={selectedValue}
        id="country-test"
        label="Country"
        width={250}
        defaultValue="ALL"
      />
    </Box>
  );
};
export default CountrySelect;
