import { useState } from "react";
import ReactSelect from "../../../components/third-party/ReactSelect";
import Box from "@mui/material/Box";

const YearSelect = ({getValue}) => {
  const [selectedValue, setSelectedValue] = useState(new Date().getFullYear());
  // eslint-disable-next-line no-unused-vars
  const [dropdownYearOptions, setDropdownYearOptions] = useState([
    { value: new Date().getFullYear(), label: new Date().getFullYear() },
  ]);

  const handleSelect = (option) => {
    getValue(option?.value,"selectedYear")
    setSelectedValue(option?.value);
  };

  return (
    <Box>
      <ReactSelect 
        name={"year"}
        options={dropdownYearOptions}
        handleChange={(selectedOptions, e) => {
          handleSelect(selectedOptions);
        }}
        value={selectedValue}
        id="country-test"
        label="Year"
        width={250}
        defaultValue="ALL"
      />
    </Box>
  );
};
export default YearSelect;
