import { ClickAwayListener, Paper, Popper } from "@mui/material";
import Transitions from "../@extended/Transitions";
import { useTheme } from "@mui/material/styles";

const CustomPopper = ({ open, anchorRef, handleClose, children, handleToggle }) => {
  const theme = useTheme();
  return (
    <>
      <Popper
        placement={"left-start"}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        // popperOptions={{
        //   modifiers: [
        //     {
        //       name: "offset",
        //       options: {
        //         offset: [0, 9],
        //       },
        //     },
        //   ],
        // }}
        sx={{ zIndex: 3 }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            <Paper
              sx={{
                boxShadow: theme.customShadows.z2,
                width: "100%",
                minWidth: 285,
                maxWidth: 420,
                [theme.breakpoints.down("md")]: {
                  maxWidth: 285,
                },
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                {children}
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default CustomPopper;
