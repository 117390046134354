import { createSlice } from '@reduxjs/toolkit'


const initialState = {
    marketIntelligenceDetails: {},
    marketIntelligenceloading: false,
    marketIntelligenceError: false,
    MarketIntellgnce: []
}
const userReducer = createSlice({

    name: 'marketIntelligenceReducer',
    initialState,
    reducers: {
        setmarketIntelligenceActivityData(state, action) {
            state.MarketIntellgnce = [...action.payload]
        },
        getmarketIntelligence(state) {
            return { ...state, marketIntelligenceloading: true };
        },
        updatemarketIntelligence(state) {
            return { ...state, marketIntelligenceloading: true };
        },
        getmarketIntelligence_successful(state, action) {
            return {
                ...state, marketIntelligenceDetails: action.payload,
                marketIntelligenceloading: false
            }
        },
        getmarketIntelligence_error(state, action) {
            return {
                ...state, marketIntelligenceDetails: action.payload.body,
                marketIntelligenceError: true
            }
        },
        resetStatus(state) {
            return {
                ...state, marketIntelligenceloading: false,
                marketIntelligenceError: false,
                marketIntelligenceDetails: {}
            };
        },
    }
})
export const {
    getmarketIntelligence,
    getmarketIntelligence_successful,
    getmarketIntelligence_error,
    resetStatus,
    updatemarketIntelligence,
    setmarketIntelligenceActivityData
} = userReducer.actions;

export default userReducer.reducer