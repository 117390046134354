import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    uploadSignedDocumentDetails: {},
    uploadSignedDocumentLoading: false,
    uploadSignedDocumentError: false,
    uploadSignedDocument: []
}
const userReducer = createSlice({

    name: 'uploadSignedDocument',
    initialState,
    reducers: {
        getSignedDocumentData(state, action) {
            state.uploadSignedDocument = {...action.payload}
        },
        getSignedDocumentHashData(state) {
            return { ...state, uploadSignedDocumentLoading: true };
        },
        getSignedDocument(state) {
            return { ...state, uploadSignedDocumentLoading: true };
        },
        getSignedDocument_successful(state, action) {
            return {
                ...state, uploadSignedDocumentDetails: action.payload,
                uploadSignedDocumentError: false
            }
        },
        getSignedDocument_error(state, action) {
            return {
                ...state, uploadSignedDocumentDetails: action.payload.body,
                uploadSignedDocumentError: true
            }
        },
        resetStatus(state) {
            return {
                ...state, uploadSignedDocumentLoading: false,
                uploadSignedDocumentError: false,
                uploadSignedDocumentDetails: {}
            };
        },
    }
})
export const {
    getSignedDocumentData,
    getSignedDocumentHashData,
    getSignedDocument,
    getSignedDocument_successful,
    getSignedDocument_error,
    resetStatus
} = userReducer.actions;

export default userReducer.reducer