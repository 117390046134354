import cookie from 'js-cookie';

const convertToHours = (time, unit) => {
  switch (unit) {
    case 'seconds':
      return time / 3600; // 3600 seconds in an hour
    case 'minutes':
      return time / 60; // 60 minutes in an hour
    case 'hours':
      return time; // already in hours
    default:
      throw new Error('Invalid time unit');
  }
};

const SESSION_EXPIRATION_TIME = 24; // Example: 3 minutes
const SESSION_EXPIRATION_UNIT = 'hours'; // Can be 'seconds', 'minutes', or 'hours'




export const SessionWeb = (data) => {
  // AuthenticateUser(data);
  
  const sessionCookie = cookie.get('SESSION_BAYAGRI');
  const currentDate = new Date();
  
  if (sessionCookie) {
    const savedDate = new Date(sessionCookie);
    let diff = (currentDate.getTime() - savedDate.getTime()) / 1000; 
    diff /= 60 * 60; 
    const hrs = Math.abs(diff.toFixed(2));
    // const hrs = 0.97;
  console.log(hrs)
    const expirationHours = convertToHours(SESSION_EXPIRATION_TIME, SESSION_EXPIRATION_UNIT).toFixed(2);
  console.log(expirationHours)
    if (hrs < expirationHours) {
      cookie.set('SESSION_BAYAGRI', currentDate);
      cookie.set('SESSION_BAYAGRI_STATUS', 'ACTIVE');
      console.log(expirationHours-hrs)
    } else {
      cookie.remove('SESSION_BAYAGRI');
      cookie.remove('SESSION_BAYAGRI_STATUS');
    }
  
    return hrs < expirationHours;
  } else {
    return true;
  }
}
  
  export const logout = () => {
    cookie.remove('SESSION_BAYAGRI');
    cookie.remove('SESSION_BAYAGRI_STATUS');
  };
  


//   const sessionCookie = cookie.get('SESSION_BAYAGRI');
//   const currentDate = new Date();
  
//   if (sessionCookie) {
//     const savedDate = new Date(sessionCookie);
//     let diff = (currentDate.getTime() - savedDate.getTime()) / 1000;
//     diff /= 60 * 60;
//     const hrs = Math.abs(Math.round(diff));
  
//     if (hrs <= SESSION_EXPIRATION_HOURS) {
//       cookie.set('SESSION_BAYAGRI', currentDate);
//       cookie.set('SESSION_BAYAGRI_STATUS', 'ACTIVE');
//       console.log('session to stop in', SESSION_EXPIRATION_HOURS-hrs)
//     } else {
//       cookie.remove('SESSION_BAYAGRI');
//       cookie.remove('SESSION_BAYAGRI_STATUS');
//     }
  
//     return hrs <= SESSION_EXPIRATION_HOURS;
//   } else {
//     return true;
//   }
// }
  
//   export const logout = () => {
//     cookie.remove('SESSION_BAYAGRI');
//     cookie.remove('SESSION_BAYAGRI_STATUS');
//   };

// export const extendSession = () => {
//   const sessionCookie = cookie.get('SESSION_BAYAGRI');
//   if (sessionCookie) {
//     const currentDate = new Date();
//     cookie.set('SESSION_BAYAGRI', currentDate);
//     console.log("SESSION EXTENDED BASED ON USER ACTIVITY:::");
//   }
// };


