import { React, useRef } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import {
  // createTheme,
  // useTheme,
  // styled,
} from "@mui/material/styles";
// import { outlinedInputClasses } from "@mui/material/OutlinedInput";
// import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

// const StyledListTyphgy = styled(DesktopDatePicker)(({ theme }) => ({
//   "& .MuiInputBase-input ": {
//     fontSize: 11,
//     width: "100%",
//     height: 60,

//     //  fontSize:11.5
//   },
//   "& .MuiInputBase-root": {
//     // marginRight:10,
//     borderColor: "red",
//     marginLeft: 0,
//     height: 36,
//     borderWidth: 1,
//   },
//   "& .MuiSvgIcon-root": {
//     width: "0.7em",
//     height: "0.7em",
//   },
// }));

const useStyles = {
  mainbox: {
    width: "100%",
    height: "auto",
    backgroundColor: "transparent",
  },
  intelligencedataview: {
    display: "flex",
    displayDirection: "row",
    height: 100,
  },
  Fieldtitle: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    paddingLeft: 0,
  },
  FieldBase: {
    width: "100%",
    display: "flex",
    paddingTop: 4,
  },
};

// const customTheme = (outerTheme) =>
//   createTheme({
//     palette: {
//       mode: outerTheme.palette.mode,
//     },
//     components: {
//       MuiTextField: {
//         styleOverrides: {
//           root: {
//             "--TextField-brandBorderColor": "#E0E3E7",
//             "--TextField-brandBorderHoverColor": "#B2BAC2",
//             "--TextField-brandBorderFocusedColor": "lightgrey",
//             "& label.Mui-focused": {
//               color: "var(--TextField-brandBorderFocusedColor)",
//             },
//           },
//         },
//       },
//       MuiOutlinedInput: {
//         styleOverrides: {
//           notchedOutline: {
//             borderColor: "var(--TextField-brandBorderColor)",
//           },
//           root: {
//             [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
//               borderColor: "var(--TextField-brandBorderHoverColor)",
//             },
//             [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
//               borderColor: "var(--TextField-brandBorderFocusedColor)",
//             },
//           },
//         },
//       },
//     },
//   });

export default function MDDateField(props) {
  // const outerTheme = useTheme();
  const dateRef = useRef();
  const updatedValue = (e) => {
    const newValue = e.target.value;
    // const dte = dayjs(newValue)
    // const validdate = `${dayjs(newValue).get('year')}/${dayjs(newValue).get('month')+1}/${dayjs(newValue).get('date')}`
    // console.log("kkkkkvaliddate",dte.get('month'),newValue,validdate)

    props.UpdateSubmittedBy(newValue);
  };
  const max = props.max || ''
  return (
    <Box
      style={{ height: 60, width: props.width, marginRight: 15 }}
      sx={{ pt: props.paddingtop }}
    >
      <Typography
        sx={{ fontSize: props.fontSize }}
        style={{ ...useStyles.Fieldtitle }}
        component="div"
      >
        {props.title}
      </Typography>
      <Paper  elevation={0} style={{ ...useStyles.FieldBase }}>
        <input
          className="datestyle"
          style={{border:props.Error&&(props.value||props.value.length<1)?'1px solid red':''}}
          type="date"
          max={max}
          value={props.value}
          onChange={(e) => updatedValue(e)}
          onClick={() => {
            // open the datepicker when click input type date
            if (dateRef?.current) dateRef.current?.showPicker();
          }}
          ref={dateRef}
        ></input>
        {/* <ThemeProvider theme={customTheme(outerTheme)}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StyledListTyphgy 
            slotProps={{
              textField: {
                error: false,
              },
            }}
            disablePast={true} onChange={(newValue) => updatedValue(newValue)} defaultValue={dayjs()} value={props.value?(dayjs(props.value)):null} />
            </LocalizationProvider>
            </ThemeProvider> */}
      </Paper>
    </Box>
  );
}
