import { createSlice } from '@reduxjs/toolkit'


const initialState = {
    filePathDetails: {},
    filePathloading: false,
    filepathError: false,
    filePath: {}
}
const userReducer = createSlice({

    name: 'DownloadFilePath',
    initialState,
    reducers: {
        setFilePath(state, action) {
            state.file = {...action.payload}
        },
        getFilePath(state) {
            return { ...state, filePathloading: true };
        },
        updateFilePath(state) {
            return { ...state, filePathloading: true };
        },
        getFilePath_successful(state, action) {
            return {
                ...state, filePathDetails: action.payload,
                filePathloading: false
            }
        },
        getFilePath_error(state, action) {
            return {
                ...state, filePathDetails: action.payload.body,
                filepathError: true
            }
        },
        resetStatus(state) {
            return {
                ...state, filePathloading: false,
                filepathError: false,
                filePathDetails: {}
            };
        },
    }
})
export const {
    getFilePath,
    getFilePath_successful,
    getFilePath_error,
    resetStatus,
    updateFilePath,
    setFilePath
} = userReducer.actions;

export default userReducer.reducer