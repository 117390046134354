/* eslint-disable react-hooks/exhaustive-deps */
import {
  Fragment,
  useState,
  useEffect,
  forwardRef,
  useRef, 
  useImperativeHandle,
} from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../../components/ui/Loader";
import { openSnackbar } from "../../../../../components/ui/Toastr";
import util from "util";
import Authorization from "../../../../../utils/authorization";
import { apiURL } from "../../../../../services/apiUrlConfig";
import { submissionCompletionStatus } from "../../../../../utils/helper";
import {
  getOrderManageObj,
  setActivityEnable,
} from "../../../../../store/reducers/contracts";
import {
  setPaymentActivityData,
  resetStatus,
  updateOrderdetails,
  getOrderDetails,
} from "../../../../../saga/saga/orderMangeSaga";

import { PAYMENT_MANAGEMENT_COUNTRY_DAYS } from '../../../../../constants'

const SUCCESS = 200;
const ACTIONTYPE = "EDIT";
const ERROR_MSG = "Please check internet or try again!";


const paymentSchema = (ids, activitytypeId, days) => {
  return {
    contractids: ids,
    activitytypeid: activitytypeId,
    submitby: days,
  };
};

// const orderRange = { min: 5, max: 90 };


// const range = (start, end) => {
//   return Array(end - start + 1)
//     .fill()
//     .map((_, index) => start + index);
// };
const SelectionAccounnts = (props) => {
  return (
    <FormControl sx={{ m: 1, minWidth: 50 }}>
      <Select
        value={props.value}
        onChange={(e) => props.valueChange(e)}
        style={{
          width: 140,
          height: 40,
          fontSize: 12,
          backgroundColor: "white",
        }}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        MenuProps={{
          disableScrollLock: true,
        }}
      >
        {props.orderRangeList?.length > 0 &&
          props.orderRangeList.map((itm, index) => {
            return (
              <MenuItem sx={{ fontSize: 11 }} value={parseInt(itm)}>
                {itm === "loading..." ? "loading..." : itm + " days"}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
};

const PaymentManagement = forwardRef((props, ref) => {
  const temporaryPaymentValue = useSelector(
    (state) => state.orderMangeSaga.PAYMENT
  );
  const [allowNav, setAllowNav] = useState(false);
  const [orderValue, setOrderValue] = useState(
    Number(temporaryPaymentValue) || "loading..."
  );
  const userDetaisl = Authorization.authUser;

  const action = useSelector((state) => state.common.ContractActionType);
  const getAccounts = useSelector((state) => state.contracts.accounts);
  const getConditionalValues = useSelector((state) => state.contracts.condsAndUncondsObj);


  const countrycode = getConditionalValues.contractCountryCode;  
  
 
  const updatedOrderRange = PAYMENT_MANAGEMENT_COUNTRY_DAYS[countrycode] || PAYMENT_MANAGEMENT_COUNTRY_DAYS?.OTHERS   //[5, 30];
  const orderRangeList =
    orderValue === "loading..." ? ["loading..."] : updatedOrderRange;

  const paymentSubmitStatus = useSelector(
    (state) => state.orderMangeSaga.orderdetailsloading
  );
  const paymentSubmitted = useSelector(
    (state) => state.orderMangeSaga.orderManagementDetails
  );
  const orpaymentSubmitError = useSelector(
    (state) => state.orderMangeSaga.orderdetailsError
  ); //
 
  const selectedAccountIds =
    getAccounts &&
    getAccounts.map((account) => {
      return account.id;
    });
  const getInterface =
    action.type === ACTIONTYPE ? [action.contratId] : selectedAccountIds;
  const reqBody = paymentSchema(getInterface, props.id, orderValue);
  const updateApi = apiURL.ppp.updateOrderPayment ?? "";

  const createContractApi = util.format(updateApi, userDetaisl.id);

  const inputRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {}, [temporaryPaymentValue]);

  useImperativeHandle(
    ref,
    () => {
      return {
        submitPaymentDetails() {
          dispatch(
            updateOrderdetails({ url: createContractApi, body: reqBody })
          );
          setAllowNav(true);
        },
      };
    },
    [orderValue]
  );

  const valueChange = async (e) => {
    setOrderValue(e.target?.value);
    const completionStatus = await submissionCompletionStatus({});
    dispatch(getOrderManageObj({ status: completionStatus, id: 2 }));
    dispatch(setActivityEnable({ id: 2, enable: true }));
    dispatch(setPaymentActivityData(e.target?.value));
  };

  useEffect(() => {
    if (!temporaryPaymentValue) {
      if (action.type === ACTIONTYPE) {
        setOrderValue("loading...");
        const getApi = apiURL.ppp.getOrderPayment ?? "";
        const createGetApi = util.format(
          getApi,
          userDetaisl.id,
          action.contratId
        );
        dispatch(getOrderDetails({ url: createGetApi, isFor: "PAYMENT" }));
      } else {
        setOrderValue( parseInt(PAYMENT_MANAGEMENT_COUNTRY_DAYS[countrycode]) || parseInt(PAYMENT_MANAGEMENT_COUNTRY_DAYS?.OTHERS));
      }
    }
  }, [action, temporaryPaymentValue]);

  const popUpSuccessr = (msg, type) => {
    openSnackbar({
      message: msg,
      variant: type,
    });
  };
 
  useEffect(() => {
    if (paymentSubmitStatus === false && !paymentSubmitted) {
      const errormsg = paymentSubmitted?.message;
      const noNetworkMessage = ERROR_MSG;
      const msg = paymentSubmitted?.status ? errormsg : noNetworkMessage;
      popUpSuccessr(msg, "error");
      allowNav && dispatch(resetStatus());
    } else if (paymentSubmitted?.status === SUCCESS) {
      if (
        action.type === ACTIONTYPE &&
        typeof paymentSubmitted.body === "object"
      ) {
        setOrderValue(temporaryPaymentValue || parseInt(PAYMENT_MANAGEMENT_COUNTRY_DAYS[countrycode]) || parseInt(PAYMENT_MANAGEMENT_COUNTRY_DAYS?.OTHERS)) 
      } else {
        if (
          action.type !== ACTIONTYPE &&
          typeof paymentSubmitted.body === "object"
        ) {
          setOrderValue(temporaryPaymentValue || parseInt(PAYMENT_MANAGEMENT_COUNTRY_DAYS[countrycode]) ||  parseInt(PAYMENT_MANAGEMENT_COUNTRY_DAYS?.OTHERS)) 
        }
        popUpSuccessr(paymentSubmitted?.message, "success");
        allowNav && props.nextActivity();
        allowNav && dispatch(resetStatus());
        dispatch(setActivityEnable({ id: 2, enable: false }));
        const completionStatus = submissionCompletionStatus({ orderValue });
        allowNav &&
          dispatch(getOrderManageObj({ status: completionStatus, id: 2 }));
      }
    }
  }, [paymentSubmitStatus, paymentSubmitted]);

  return (
    <Fragment>
      {paymentSubmitStatus && !orpaymentSubmitError && <Loader />}
      <Box ref={inputRef} display="flex" alignItems={"center"} gap={2}>
        80% of invoices need to be paid within
        <SelectionAccounnts
          value={orderValue}
          valueChange={(e) => valueChange(e)}
          orderRangeList={orderRangeList}
        />
        After payment term
      </Box>
    </Fragment>
  );
});

export default PaymentManagement;
