// types
import { createSlice } from "@reduxjs/toolkit";
import {ACTIVITIES_CUSTOM_ORDER } from "../../constants";

// initial state
const initialState = {
  accounts: [],
  activitiesList: [],
  condsAndUncondsObj: {},
  prevSelectedAccounts: [],
  contractsList: [],
  activitySum: 0,
  completionStatus: {},
  orderManage: {},
  activityEnable: {},
  foreCastListSelected:{},
  foreCastAcc:[],
  marketInt:[],
  advTrails:[],
  foreCastVarieties:[],
  forecastInfo:[],
  POGDeliveryAcc:[],
  POGDeliveryInfo:[],
  miDev:[]
};

// ==============================|| SLICE - Contracts  ||============================== //

const contractsRedux = createSlice({
  name: "contractsRedux",
  initialState,
  reducers: {
    getMarketIntelligenceSelected(state, action){
      state.marketInt = [...action.payload];
    },
    getAdvTrailsSelected(state, action){
      state.advTrails = [...action.payload];
    },
    getAccountsSelected(state, action) {
      state.accounts = action.payload.accounts;
    },
    setVarietiesForecast(state, action) {
      state.foreCastVarieties = [...action.payload];
    },
    getForeCastAccSelected(state, action) {
      state.foreCastAcc = [...action.payload];
    },
    getMIDevSelected(state, action) {
      state.miDev = [...action.payload];
    },
    getPOGDeliveryAccSelected(state, action) {
      state.POGDeliveryAcc = [...action.payload];
    },
    getForeCastListItemSelected(state, action) {
      state.foreCastListSelected = action.payload.accounts;
    },
    getActivitiesSelected(state, action) {
      state.activitiesList = action.payload.activitiesList;
      state.salesVolume = {}
    },
    setCondsAndUncondsObj(state, action) {
      state.condsAndUncondsObj = action.payload.condsAndUncondsObj;
    },
    getSalesVolumeObj(state, action) {
      state.completionStatus = { ...state.completionStatus, [action.payload.id]: action.payload.status };
    },
    getOrderManageObj(state, action) {
      state.completionStatus = { ...state.completionStatus, [action.payload.id]: action.payload.status };
    },
    getPrevSelectedAccounts(state, action) {
      state.prevSelectedAccounts = action.payload.prevSelectedAccounts;
    },
    getContractsList(state, action) {
      state.contractsList = action.payload.contractsList;
    },
    setActivitySum(state, action) {
      state.activitySum = action.payload.activitySum;
    },
    setPaymentManageValue(state, action) {
      state.paymentManagementValue = action.payload;
    },
    setForecastSelection(state, action) {
      state.forecastInfo = [...action.payload];
    },
    setPOGDeliverySelection(state, action) {
      state.POGDeliveryInfo = [...action.payload];
    },
    setResetCompletiontatus(state) {
      state.completionStatus = {};
      state.activityEnable = {};
    },
    resetActivityEnable(state, action) {
      state.activityEnable = {}
    },
    setActivityEnable(state, action) {
      state.activityEnable = { ...state.activityEnable, [action.payload.id]: action.payload.enable }
    }
  },
});

export default contractsRedux.reducer;

export const {
  getAccountsSelected,
  getActivitiesSelected,
  setCondsAndUncondsObj,
  getPrevSelectedAccounts,
  getContractsList,
  setActivitySum,
  getSalesVolumeObj,
  getOrderManageObj,
  setResetCompletiontatus,
  setActivityEnable,
  setPaymentManageValue,
  getForeCastListItemSelected,
  getForeCastAccSelected,
  getMarketIntelligenceSelected,
  getAdvTrailsSelected,
  setVarietiesForecast,
  resetActivityEnable,
  setForecastSelection,
  getPOGDeliveryAccSelected,
  setPOGDeliverySelection,
  getMIDevSelected
} = contractsRedux.actions;
