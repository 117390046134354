import React from 'react';
import { Modal, Typography, Box, Button, Paper } from '@mui/material';


 const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  outline: 'none',
  p: 4,
  display: 'flex',
  flexDirection: 'column'
};

const MiModal = (props) => {

  const handleClose = () => {
    props.cancel.cancel()

  }
  const handleOk = () => {
    props.ok.ok()

  }
  return (
    <Modal
      open={props.open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Paper elevation={0} sx={{ p: 1 }}>
          <Typography id="modal-modal-title" variant="h5" component="h2" align="center" >
            {props.title}
          </Typography>
        </Paper>
        <Paper elevation={0} sx={{ pb: 4 }}>
          <Typography id="modal-modal-description" align="center"  >
            {props.description}
          </Typography>
        </Paper >
        <Paper align="center" sx={{ p: 1 }} elevation={0}>
          <Button variant="outlined" sx={{ mr: 2, width: 130 }} onClick={handleClose} >{props.cancel.buttonName}</Button>
          <Button variant="contained" sx={{ mr: 2, width: 130 }} color="success" onClick={handleOk}>{props.ok.buttonName}</Button>

        </Paper >
      </Box>
    </Modal>
  )
}

export default MiModal