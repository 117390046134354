/* eslint-disable react-hooks/exhaustive-deps */
import {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { Grid, OutlinedInput, Tooltip, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ReactSelect from "../../../../../../components/third-party/ReactSelect";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../../../../../components/ui/Loader";
import { apiURL } from "../../../../../../services/apiUrlConfig";
import {
  getRequest,
  saveOrUpdateRequest,
} from "../../../../../../services/apiUtils";
import { openSnackbar } from "../../../../../../components/ui/Toastr";
import {
  ErrorMsg,
  getEndMonthAndYear,
  getStartMonthAndYear,
} from "../../../../../../utils/helper";
import Authorization from "../../../../../../utils/authorization";
import util from "util";
import {
  getOrderManageObj,
  setActivityEnable,
} from "../../../../../../store/reducers/contracts";
import { submissionCompletionStatus } from "../../../../../../utils/helper";
import { setBrandRepresentation } from "../../../../../../store/reducers/activities";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { CONTRACT_YEAR } from "../../../../../../constants";


const StyledDatePicker = styled(DatePicker)({
  ".MuiInputAdornment-root ": {
    marginLeft: 0,
  },
  "& input.Mui-disabled":{
    "-webkit-text-fill-color":"#413f3f",
    cursor: "not-allowed",
    pointerEvents: "auto"
  }
});

const StyledOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  "& input.Mui-disabled":{
    "-webkit-text-fill-color":"#413f3f",
    cursor: "not-allowed",
    pointerEvents: "auto"
  }
 }));

function createData(data) {
  return { ...data };
}
const initialRowData = {
  marketingactivitytypeid: "",
  targetnumber: "",
  qualitativeparams: "",
  startdate: null,
  enddate: null,
};
/**
 * BrandRepresentation component
 * This comoponent is combine with offline and online marketing actvities
 */
const BrandRepresentation = forwardRef((props, ref) => {
  const [dgData, setDgData] = useState([]);
  const [newRowData, setNewRowData] = useState(initialRowData);
  const [error, setErrors] = useState(false);
  const [inputErrors, setInputErrors] = useState({
    marketingactivitytypeid: "",
    targetnumber: "",
    qualitativeparams: "",
    startdate: "",
    enddate: "",
  });
  const [isLoader, SetLoader] = useState(false);
  const [selectedAccountIds, setSelectedAccountIds] = useState(null);
  const [open, setOpen] = useState(false);
  const [openEndDate, setOpenEndDate] = useState(false);

  const action = useSelector((state) => state.common.ContractActionType);
  const { brandRepresentation } = useSelector((state) => state.activities);
  const { accounts } = useSelector((state) => state.contracts);
  const dispatch = useDispatch();

  const isEditApplicable = action?.type === "EDIT" ? true : false;
  const authUser = Authorization.getAuthUser();
  const inputRef = useRef(null);

  useEffect(() => {
    let accountsID = null;
    if (accounts?.length) {
      const arrayIds = accounts.map((acc) => acc?.id);
      accountsID = [...new Set(arrayIds)];
      setSelectedAccountIds(accountsID);
    }
    if (isEditApplicable && !brandRepresentation?.isGetAPICalled) {
      getOneByID(props?.id, accountsID);
    }
    if (brandRepresentation?.activities?.length > 0) {
      setDgData(brandRepresentation?.activities);
    }
    if (Object.keys(brandRepresentation?.rowData)?.length) {
      setNewRowData({ ...brandRepresentation?.rowData });
    }
    dispatch(setActivityEnable({ id: props?.id, enable: true }));
  }, []);

  useImperativeHandle(
    ref,
    () => {
      return {
        submitBrandRep() {
          createBrandRep();
        },
      };
    },
    [dgData, error, newRowData]
  );

  useEffect(() => {
    let isUpdateRowData = Object.values(newRowData).every(
      (error) => error === "" || error === null
    );
    dispatch(
      setBrandRepresentation({
        brandRepresentation: {
          ...brandRepresentation,
          activities: [...dgData],
          rowData: !isUpdateRowData ? { ...newRowData } : {},
        },
      })
    );
  }, [dgData, newRowData]);

  const submitAPI = (url, method, payload, queryParams, successMsg) => {
    const condUrl = util.format(url, authUser?.id);
    saveOrUpdateRequest({
      url: condUrl,
      method: method,
      data: payload,
      queryParams: queryParams,
    })
      .then((response) => {
        if (response?.status === 200) {
          openSnackbar({ message: successMsg, variant: "success" });
          const completionStatus = submissionCompletionStatus({ payload });
          dispatch(
            getOrderManageObj({ status: completionStatus, id: props.id })
          );
          dispatch(setActivityEnable({ id: props?.id, enable: false }));
          props.nextActivity();
        }
      })
      .catch((error) => {
        const errorRes = ErrorMsg(error);
        openSnackbar({ message: errorRes, variant: "error" });
      });
  };

  const createBrandRep = () => {
    let isSubmitBtn = dgData?.length === 0 ? true : false;
    if (isSubmitBtn) {
      validateFields();
    } else if (!error) {
      const {
        ppp: { activities },
      } = apiURL;
      const url = activities?.createOfflineMarket;

      const payloadObj = {
        contractids: selectedAccountIds,
        activitytypeid: props?.id,
        activities: [...dgData],
      };
      submitAPI(
        url,
        "POST",
        payloadObj,
        {},
        `Brand Representation Activities is ${
          isEditApplicable ? "updated" : "created"
        } successfully`
      );
    }
  };

  const getOneByID = (id, accountIds) => {
    const {
      ppp: { activities },
    } = apiURL;
    SetLoader(true);
    const condUrl = util.format(
      activities.getOneMarketingActivities,
      authUser?.id,
      accountIds
    );
    getRequest({
      url: condUrl,
      queryParams: {
        activityTypeId:id
      },
    })
      .then((response) => {
        SetLoader(false);
        let result = response?.body;
        let updated = [];
        if (result?.length) {
          const updated = [...result];
          setDgData(updated);
        }
        dispatch(
          setBrandRepresentation({
            brandRepresentation: {
              ...brandRepresentation,
              isGetAPICalled: true,
              activities: updated,
            },
          })
        );
      })
      .catch((error) => {
        SetLoader(false);
        const errorRes = ErrorMsg(error);
        openSnackbar({ message: errorRes, variant: "error" });
      });
  };

  const validateFields = (preventUpdate) => {
    const newErrors = {
      marketingactivitytypeid: newRowData.marketingactivitytypeid
        ? ""
        : "Activity Type is required",
      targetnumber: newRowData.targetnumber ? "" : "Target Number is required",
      qualitativeparams: newRowData.qualitativeparams
        ? ""
        : "Qualitative Parameters is required",
      startdate: newRowData.startdate !== null ? "" : "Start Month is required",
      enddate: newRowData.enddate !== null ? "" : "End Month  is required",
    };
    !preventUpdate && setInputErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };

  const handleInput = (e, field) => {
    setNewRowData((prevData) => ({
      ...prevData,
      [field]:
        field === "targetnumber" ? Number(e.target.value) : e.target.value,
    }));
    setInputErrors((prevErrors) => ({
      ...prevErrors,
      [field]: "",
    }));
  };

  const handlestartMonthChange = (newVal, field) => {
    const output = getStartMonthAndYear(newVal);
    setNewRowData((prevData) => ({
      ...prevData,
      [field]: output,
    }));
    setInputErrors((prevErrors) => ({
      ...prevErrors,
      [field]: "",
    }));
  };

  const handleendMonthChange = (newVal, field) => {
    const output = getEndMonthAndYear(newVal);
    setNewRowData((prevData) => ({
      ...prevData,
      [field]: output,
    }));
    setInputErrors((prevErrors) => ({
      ...prevErrors,
      [field]: "",
    }));
  };

  const handleSelectChange = (newVal, field) => {
    setNewRowData((prevData) => ({
      ...prevData,
      [field]: newVal.value,
    }));
    setInputErrors((prevErrors) => ({
      ...prevErrors,
      [field]: "",
    }));
  };

  const updateCompletedStatus = () => {
    const completionStatus = submissionCompletionStatus({});
    dispatch(getOrderManageObj({ status: completionStatus, id: props.id }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateFields()) {
      const newRow = createData({ ...newRowData });
      setDgData((prevData) => [...prevData, newRow]);
      setNewRowData(initialRowData);
      setErrors(false);
      updateCompletedStatus();
    } else {
      setErrors(true);
    }
  };

  const handleDelete = (key) => {
    setDgData((prevRows) => prevRows.filter((row, index) => index !== key));
    updateCompletedStatus();
  };

  // useEffect(() => {

  // }, [dgData]);

  //original form
  const getActivityLabel = (id) => {
    if (props?.options?.length) {
      const result = props?.options.find((ops) => ops.value === id);
      return result?.label;
    }
  };

  const isInCurrentMonth = (date) =>
    newRowData?.startdate !== null && dayjs(date) <= newRowData?.startdate;

  const isDisableLastMonth = (date) =>
    newRowData?.enddate !== null && dayjs(date) >= newRowData?.enddate;

  const getSumOfTarget = (data) => {
    let total = 0;
    if (dgData?.length) {
      dgData.forEach((dd) => {
        total += Number(dd?.targetnumber);
      });
      return total;
    }
  };
  const currentYear = dayjs(`${CONTRACT_YEAR}-01-01`);
  const nextYear = dayjs(`${CONTRACT_YEAR + 1}-12-31`);
  return (
    <div
      style={{ backgroundColor: "white", borderRadius: "10px" }}
      ref={inputRef}
    >
      {isLoader && <Loader />}
      <form action="submit" onSubmit={handleSubmit}>
        {/* top input grid */}
        <Grid container spacing={2}>
          <Grid item xs={2.5}>
            <div>
              <Typography variant="body2">Activity type</Typography>
              <Tooltip title={newRowData.marketingactivitytypeid}></Tooltip>
              <ReactSelect
                name={"Activity type"}
                options={props?.options}
                handleChange={(e) =>
                  handleSelectChange(e, "marketingactivitytypeid")
                }
                id="activity-type"
                width={"100%"}
                value={newRowData.marketingactivitytypeid}
                className={
                  inputErrors.marketingactivitytypeid
                    ? "error-border-select"
                    : ""
                }
                sx={{
                  "& .MuiInputBase-input": {
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  },
                }}
              />
              {inputErrors.marketingactivitytypeid && (
                <p style={{ color: "red", fontSize: "10px" }}>
                  {inputErrors.marketingactivitytypeid}
                </p>
              )}
            </div>
          </Grid>
          <Grid item xs={2}>
            <div>
              <Typography variant="body2" sx={{ whiteSpace: "nowrap" }}>
                Target number
              </Typography>
              <Tooltip title={newRowData.targetnumber}>
                <OutlinedInput
                  type="number"
                  className={inputErrors.targetnumber ? "error-border" : ""}
                  onChange={(e) => handleInput(e, "targetnumber")}
                  value={newRowData.targetnumber}
                  placeholder="Target number"
                  sx={{
                    "& .MuiInputBase-input": {
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    },
                  }}
                />
              </Tooltip>
              {inputErrors.targetnumber && (
                <p style={{ color: "red", fontSize: "10px" }}>
                  {inputErrors.targetnumber}
                </p>
              )}
            </div>
          </Grid>
          <Grid item xs={2}>
            <div>
              <Typography variant="body2" sx={{ whiteSpace: "nowrap" }}>
                Qualitative parameters
              </Typography>
              <Tooltip title={newRowData.qualitativeparams}>
                <OutlinedInput
                  className={
                    inputErrors.qualitativeparams ? "error-border" : ""
                  }
                  type="text"
                  onChange={(e) => handleInput(e, "qualitativeparams")}
                  value={newRowData.qualitativeparams}
                  placeholder="Qualitative Parameters/Remarks"
                  sx={{
                    "& .MuiInputBase-input": {
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    },
                  }}
                />
              </Tooltip>
              {inputErrors.qualitativeparams && (
                <p style={{ color: "red", fontSize: "10px" }}>
                  {inputErrors.qualitativeparams}
                </p>
              )}
            </div>
          </Grid>
          <Grid item xs={2}>
            <div>
              <Typography variant="body2">Start month</Typography>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StyledDatePicker
                  open={open}
                  onOpen={() => setOpen(true)}
                  onClose={() => setOpen(false)}
                  placeholder="Pick a date"
                  value={
                    newRowData?.startdate === null
                      ? null
                      : dayjs(newRowData?.startdate)
                  }
                  views={["month", "year"]}
                  onChange={(e) => handlestartMonthChange(e, "startdate")}
                  className={inputErrors.startdate ? "error-border-date" : ""}
                  shouldDisableMonth={isDisableLastMonth}
                  slotProps={{
                    textField: {
                      onClick: () => setOpen(true),
                    },
                    openPickerIcon: {
                      onClick: () => {
                        setOpen(true);
                      },
                    },
                  }}
                  minDate={currentYear}
                  maxDate={
                    newRowData?.enddate ? dayjs(newRowData?.enddate) : nextYear
                  }
                  format="MMM YYYY"
                />
                {inputErrors.startdate && (
                  <p style={{ color: "red", fontSize: "10px" }}>
                    {inputErrors.startdate}
                  </p>
                )}
              </LocalizationProvider>
            </div>
          </Grid>
          <Grid item xs={2}>
            <div>
              <Typography variant="body2">End month</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StyledDatePicker
                  open={openEndDate}
                  onOpen={() => setOpenEndDate(true)}
                  onClose={() => setOpenEndDate(false)}
                  placeholder="Pick a date"
                  value={
                    newRowData?.enddate === null
                      ? null
                      : dayjs(newRowData.enddate)
                  }
                  views={["month", "year"]}
                  onChange={(e) => handleendMonthChange(e, "enddate")}
                  className={inputErrors.enddate ? "error-border-date" : ""}
                  shouldDisableMonth={isInCurrentMonth}
                  slotProps={{
                    textField: {
                      onClick: () => setOpenEndDate(true),
                    },
                    openPickerIcon: {
                      onClick: () => {
                        setOpenEndDate(true);
                      },
                    },
                  }}
                  minDate={
                    newRowData?.startdate
                      ? dayjs(newRowData?.startdate)
                      : currentYear
                  }
                  maxDate={nextYear}
                  format="MMM YYYY"
                />
                {inputErrors.enddate && (
                  <p style={{ color: "red", fontSize: "10px" }}>
                    {inputErrors.enddate}
                  </p>
                )}
              </LocalizationProvider>
            </div>
          </Grid>
          <Grid item xs={1.5}>
            <div>
              <Button
              type="submit"
                variant="outlined"
                startIcon={
                  <AddIcon style={{ width: "25px", height: "25px" }} />
                }
                sx={{
                  marginTop: {
                    xl: "31px",
                    lg: "20px",
                  },
                  fontSize: "0.825rem",
                  whiteSpace: "nowrap",
                  border: "none",
                  "&:hover": {
                    border: "none",
                  },
                  "& .MuiButton-startIcon": {
                    marginRight: "1px",
                  },
                }}
              >
                Add Row
              </Button>
            </div>
          </Grid>
        </Grid>
      </form>
      <Grid
        container
        spacing={2}
        sx={{
          backgroundColor: "#EBF0F2",
          marginBottom: "20px",
          marginTop: "10px",
        }}
      >
        <Grid item xs={2}>
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            Activity type
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography
            variant="body2"
            sx={{ fontWeight: "bold", whiteSpace: "nowrap" }}
          >
            Target number
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            variant="body2"
            sx={{ fontWeight: "bold", whiteSpace: "nowrap" }}
          >
            Qualitative parameters
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            Start month
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            End month
          </Typography>
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>

      {dgData?.map((row, rowIdx) => (
        <Grid container spacing={2} id={rowIdx} key={rowIdx}>
          <Grid item xs={2} sx={{ marginBottom: "10px" }}>
            <div>
              <StyledOutlinedInput
                value={getActivityLabel(row.marketingactivitytypeid)}
                disabled
              />
            </div>
          </Grid>
          <Grid item xs={2} sx={{ marginBottom: "10px" }}>
            <div>
              <StyledOutlinedInput value={row.targetnumber} disabled />
            </div>
          </Grid>
          <Grid item xs={3} sx={{ marginBottom: "10px" }}>
            <div>
              <StyledOutlinedInput value={row.qualitativeparams} disabled />
            </div>
          </Grid>
          <Grid item xs={2} sx={{ marginBottom: "10px" }}>
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StyledDatePicker
                  views={["month", "year"]}
                  value={dayjs(row.startdate)}
                  disabled
                  format="MMM YYYY"
                />
              </LocalizationProvider>
            </div>
          </Grid>
          <Grid item xs={2} sx={{ marginBottom: "10px" }}>
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StyledDatePicker
                  views={["month", "year"]}
                  value={dayjs(row.enddate)}
                  disabled
                  format="MMM YYYY"
                />
              </LocalizationProvider>
            </div>
          </Grid>
          <Grid
            item
            xs={1}>
            <div>
              <IconButton
                onClick={() => {
                  handleDelete(rowIdx);
                }}
                aria-label="delete"
                sx={{
                  marginTop: {
                    xl: "4px",
                    lg: "2px",
                  },
                }}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          </Grid>
        </Grid>
      ))}
      {dgData?.length > 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "20px",
            backgroundColor: "#f0f9fd",
            padding: "20px",
          }}
        >
          <span
            style={{
              marginRight: "20px",
              marginLeft: "10px",
              marginBottom: "10px",
              fontWeight: "bold",
            }}
          >
            Sum of Targets
          </span>
          <span style={{ fontWeight: "bold" }}>{getSumOfTarget()}</span>
        </div>
      )}
    </div>
  );
});

export default BrandRepresentation;
