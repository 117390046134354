/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useState, useEffect } from "react";
// material-ui
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import Authorization from "../../utils/authorization";
import TargetCount from "./TargetCount";
import TrialLeader from "./TrialLeader";
import UserLeader from "./UserLeader";
import UserTrialLeader from "./UserTrialLeader";
import { apiURL } from "../../services/apiUrlConfig";
import { getRequest } from "../../services/apiUtils";
import { openSnackbar } from "../../components/ui/Toastr";
import { ErrorMsg } from "../../utils/helper";
import Loader from "../../components/ui/Loader";

const DashboardAnalytics = () => {
  const [isLoader, SetLoader] = useState(false);
  const { countryCode, selectedYear, selectedTrialExec } = useSelector((state) => state.common);
  const [trialData, setTrialData] = useState({ count: 0, data: [] });
  const [userLeaderData, setUserLeaderData] = useState({
    totalLocationCount: 0,
    totalSeedCount: 0,
    data: [],
  });
  const [userTrialLeaderData, setUserTrialLeaderData] = useState([]);
  const authUser = Authorization.getAuthUser();

  useEffect(() => {
    getTrialList();
    getUserTrialLeader();
  }, [countryCode, selectedYear, selectedTrialExec]);
  useEffect(() => {
    getUserLeader();
  }, [countryCode, selectedYear]);

  const updateTotalUser = (userLeaderDataArgs) => {
    if (userLeaderDataArgs?.length) {
      const result = userLeaderDataArgs?.find((uld) => uld?.countrycode === countryCode);
      return result?.totalUsers || 0;
    }
  };

  const getTrialList = () => {
    const { targetDashboard } = apiURL;
    callbackLoader(true);
    getRequest({
      url: `${targetDashboard.getTrialLeaderBoard}/${authUser?.id}`,
      queryParams: {
        countryCode: countryCode === "ALL" ? null : countryCode,
        year: selectedYear || null,
        trialExecution: selectedTrialExec === "ALL" ? null : selectedTrialExec,
      },
    })
      .then((response) => {
        callbackLoader(false);
        const data = response?.body?.data;
        if (Object.keys(data)?.length > 0) {
          setTrialData({
            ...trialData,
            totalLocationCount: data?.totalLocation,
            totalSeedCount: data?.totalSeedReq,
            data: data?.leaderDataBoard?.length ? data?.leaderDataBoard : [],
          });
        } else {
          setTrialData({
            totalLocationCount: 0,
            totalSeedCount: 0,
            data: [],
          });
        }
      })
      .catch((error) => {
        callbackLoader(false);
        const errorRes = ErrorMsg(error);
        openSnackbar({ message: errorRes, variant: "error" });
      });
  };
  const getUserLeader = () => {
    const { targetDashboard } = apiURL;
    callbackLoader(true);
    getRequest({
      url: `${targetDashboard.getUserLeaderBoard}/${authUser?.id}`,
      queryParams: { year: selectedYear || null },
    })
      .then((response) => {
        callbackLoader(false);
        const data = response?.body?.data;
        if (Object.keys(data)?.length > 0) {
          let condCount = 0;
          if (countryCode === "ALL") {
            condCount = data?.totalUsers;
          } else {
            condCount = updateTotalUser(data?.userLeaderBoard);
          }
          setUserLeaderData({
            ...userLeaderData,
            count: condCount,
            data: data?.userLeaderBoard?.length ? data?.userLeaderBoard : [],
          });
        } else {
          setUserLeaderData({
            count: 0,
            data: [],
          });
        }
      })
      .catch((error) => {
        callbackLoader(false);
        const errorRes = ErrorMsg(error);
        openSnackbar({ message: errorRes, variant: "error" });
      });
  };
  const getUserTrialLeader = () => {
    const { targetDashboard } = apiURL;
    callbackLoader(true);
    getRequest({
      url: `${targetDashboard.getUserTrialLeaderBoard}/${authUser?.id}`,
      queryParams: {
        countryCode: countryCode === "ALL" ? null : countryCode,
        year: selectedYear || null,
        trialExecution: selectedTrialExec === "ALL" ? null : selectedTrialExec,
      },
    })
      .then((response) => {
        callbackLoader(false);
        const data = response?.body?.data;
        if (data?.userLeaderBoard?.length > 0) {
          setUserTrialLeaderData(data?.userLeaderBoard);
        } else {
          setUserTrialLeaderData([]);
        }
      })
      .catch((error) => {
        callbackLoader(false);
        const errorRes = ErrorMsg(error);
        openSnackbar({ message: errorRes, variant: "error" });
      });
  };

  const callbackLoader = (isLoader) => {
    SetLoader(isLoader);
  };

  return (
    <Fragment>
      {isLoader && <Loader />}
      <Grid container rowSpacing={3} columnSpacing={2}>
        {/* row 1 */}
        <TargetCount
          callbackLoader={callbackLoader}
          selectedCountryCode={countryCode}
          loggedUser={authUser}
          trialLocCount={trialData?.totalLocationCount}
          totalUserCount={userLeaderData?.count}
          totalSeedCount={trialData?.totalSeedCount}
        />
        <Grid item xs={12} sm={12} md={12} lg={4}>
          <TrialLeader data={trialData?.data} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4}>
          <UserLeader data={userLeaderData?.data} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4}>
          <UserTrialLeader data={userTrialLeaderData} />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default DashboardAnalytics;
